@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.DateRangePicker {
  #border.with-color(@gray-2, bottom);
}

.DateRange {
  background-color: @gray-2;
  border-radius: @border-radius-md;
  padding: @padding-xss @padding-sm;

  :global {
    @media @phone {
      .ant-space {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.Label {
  color: @gray-6 !important;
}

.Date {
  color: @gray-7 !important;
}

.Tabs {
  margin-left: auto;

  :global {
    .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: none;
      }
    }
  }
}