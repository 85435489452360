@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Widget {
  margin-bottom: @margin-md;

  :global {
    strong {
      font-weight: 500;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.Last {
  margin-bottom: 0;
}