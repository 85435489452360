@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Skeleton {
  padding: 0 @padding-md;
}

.CompactWrapper {
  :global {
    .ant-layout-content {
      border: none;

      .ant-row {
        .ant-col {
          padding: 0;
        }
      }
    }
  }
}

.List {
  padding: @padding-md;
}

/**
* The Month Year Label
**/
.GroupLabel {
  background: @gray-3;
  border-radius: @border-radius-sm;
  color: @gray-7;
  margin-bottom: @margin-md !important;
  padding: @padding-xss @padding-xs;
  width: fit-content;
}

.HideLastTail {
  :global {
    li:last-child {
      .ant-timeline-item-tail {
        display: none;
      }
    }
  }
}

.ShowMore {
  display: flex;
  height: @height-sm;
  margin-left: calc(@margin-xs + 1px);
  padding-bottom: 0;

  :global {
    .ant-timeline-item-content {
      min-height: unset;
      transform: translateY(1px);
    }

    a {
      color: @gray-7;
    }
  }
}

.Tag {
  position: relative;
  top: -@spacing-xss;
}