@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.FarmSelect {
  font-size: @font-size-md;
  padding: @padding-sm 0 @padding-sm @padding-xs;

  :global {
    .ant-typography {
      color: @gray-8 !important;
    }
  }
}

.Icon {
  display: block;

  :global {
    svg {
      #dimensions.square-dimensions(21px);
      fill: @aqua-5;
    }
  }
}
