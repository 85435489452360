/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.Link {
  cursor: pointer;
}
.Wrapper {
  margin-bottom: 4px;
}
.Primary {
  color: #068E99;
  display: block;
  font-size: 24px;
}
.Secondary {
  display: block;
  color: #000000;
  font-size: 16px;
  line-height: 140%;
}
