@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.MobileViewRow {
  :global {
    .ctra-insightGeneric-timePeriod {
      .ant-space-item:first-child {
        display: none;
      }

      .ant-space-item:last-child {
        line-height: normal;
      }
    }
  }
}

.IconWrapper {
  position: relative;

  :global {
    .ant-badge {
      position: absolute;
      left: 0;
    }

    .anticon {
      display: block;

      svg {
        #dimensions.square-dimensions(18px);
        fill: @gray-7;
      }
    }
  }
}