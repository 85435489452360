@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Space {
  position: relative;
  :global {
    .ant-select-selection {
      &-item,
      &-placeholder,
      &-search-input {
        margin-left: @margin-lg !important;
      }
    }
    svg {
      fill: @gray-8;
    }
  }
}

.Icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.Tag {
  background-color: @turquoise-1;
  border-color: @turquoise-7;
}
