@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.EmptyPanel {
  #border.with-color(@gray-5, all, @border-radius-lg);
  background-color: @gray-3;
  padding: @padding-md;
  line-height: 0;

  :global {
    .ant-typography {
      color: @gray-7;
      font-weight: normal;
      font-size: @font-size-lg - 2px;
      margin: 0;
    }

    .anticon {
      color: @gray-7;

      svg {
        #dimensions.square-dimensions(21px);
      }
    }
  }
}
