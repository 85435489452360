@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Padded {
  padding: 0 @padding-lg;

  @media @phone {
    padding: 0 @padding-md;
  }
}

.Bordered {
  #border.base(bottom);
}

.HeaderWrapper {
  padding-bottom: @padding-lg;

  @media @phone {
    position: relative;
  }

  @media @desktop {
    :global {
      .ctra-insightDialog-statusDropdown {
        margin-right: @margin-xl;
      }
    }
  }
}
