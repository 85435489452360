@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  background-color: @gray-8;
  color: @white;
  padding: @padding-xs @padding-md;
  border-radius: @border-radius-sm;

  :global {
    svg {
      fill: @white;
    }
  }
}

.Text {
  color: @white;
  font-size: @font-size-base - 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.Title {
  color: @white !important;
  margin: 0 !important;
  font-size: @font-size-base !important;
  line-height: @height-base - 10px !important;
}
