@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Title {
  color: @teal-9 !important;
  margin-bottom: 0.3em;
}

.H6 {
  .Title;

  font-size: @heading-6-size;
  font-weight: 500;
}

.Light {
  color: @turquoise-6 !important;
}

.Black {
  color: @gray-10 !important;
}