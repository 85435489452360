@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.WidgetWrapper {
  margin-top: -@padding-sm;
}

.HideOverflow {
  overflow: hidden;
}

.Options {
  border-top: 1px solid @gray-2;
  border-bottom: 1px solid @gray-2;
  background-color: @gray-1;
  padding: @padding-md;
  gap: @padding-sm;
}

.Selector {
  padding: @padding-md;
}

.Border {
  #border.base(bottom);
}

.Close {
  margin-right: @margin-md;
  :global {
    svg {
      fill: @gray-6;
      cursor: pointer;
    }
  }
}

.Chart {
  padding: @margin-md;
}

.Save {
  margin: 0 @margin-lg;
  svg {
    margin-right: @margin-xs;
  }
}

.Saved {
  margin: 0 @margin-lg;
  :global {
    svg {
      fill: @teal-9;
      margin-right: @margin-xs;
    }
  }
}

.Dropdown {
  :global {
    .ant-select {
      border: 2px solid blue !important;
    }
  }
}
