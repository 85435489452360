@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Resolution {
  align-items: center;
  background-color: @gray-3;
  border-radius: 16px;
  color: @gray-8;
  display: inline-flex;

  :global {
    .anticon {
      margin-left: -2px;
    }
  }
}

.validation-variant(@primary-color, @lighten-value) {
  color: @primary-color;

  :global {
    .ant-tag {
      #border.with-color(@primary-color);
      background-color: lighten(@primary-color, @lighten-value);
      color: @primary-color;
      margin-bottom: @margin-xs;
    }

    .ant-typography {
      color: @primary-color;
    }
  }
}

.validated-yes {
  .validation-variant(@primary-color: @primary-color, @lighten-value: 63);
}

.validated-no {
  .validation-variant(@primary-color: @red-7, @lighten-value: 53);

  :global {
    .ant-tag {
      #border.with-color(@border-color-base);
      background-color: @white;
    }
  }
}

.validated-maybe {
  .validation-variant(@primary-color: @gray-8, @lighten-value: 100);
}
