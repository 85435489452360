@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Link {
  cursor: pointer;
}

.Steps {
  width: 100%;

  :global {
    .ant-steps-item {
      padding: 0;
      width: 100%;

      .ant-steps-item-container > .ant-steps-item-tail::after {
        width: unset;
        height: unset;
      }
    }

    .ant-steps-item-active {
      background-color: transparent;
    }
  }
}

.Smile {
  :global {
    svg {
      fill: @connecterra-teal;
    }
  }
}
