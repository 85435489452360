@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.NaviCard {
  #border.base(all, @border-radius-xl);
  cursor: pointer;
  padding: @padding-lg;

  &:hover {
    background: @gray-1;
  }
}

.Primary {
  color: @teal-8;
}
