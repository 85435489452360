@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  padding: @padding-lg;
}

.Label {
  margin-bottom: @margin-xs;
}

.Submit {
  margin-left: auto;
  padding: @padding-sm 0;
}

.Icon {
  color: @success-color;
  :global {
    svg {
      #dimensions.square-dimensions(18px);
    }
  }
}
