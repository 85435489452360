@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Space {
  line-height: 0;
}

.Icon {
  :global {
    svg {
      fill: @teal-8;
      #dimensions.square-dimensions(20px);
    }
  }
}
