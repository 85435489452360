@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  padding: 0;
  flex-wrap: nowrap;
}

.ChartWrapper {
  :global {
    canvas {
      width: 100% !important;
    }
  }
}

.Top {
  flex-direction: column-reverse;
}

.Bottom {
  flex-direction: column;
}

.Left {
  flex-direction: row-reverse;
}

.Right {
  //flex-direction: row;
}
