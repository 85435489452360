@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Row {
  #border.base(bottom);
  padding-bottom: @margin-lg;
  padding-top: @margin-lg;
}

.LastRow {
  #border.clear();
}

.Padded {
  padding-left: @margin-lg;
  padding-right: @margin-lg;

  @media @phone {
    padding: @margin-md;
  }
}

.PaddedChart {
  padding-left: @margin-lg;
  padding-right: @margin-lg;

  @media @phone {
    padding: 0;
  }
}

.Gray {
  background-color: @gray-2;
}
