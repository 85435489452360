@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Zoom {
  padding: 0 @padding-md;

  :global {
    canvas {
      max-height: unset;
      min-height: 75vh;

      @media @phone {
        min-height: 68vh;
      }
    }
  }
}
