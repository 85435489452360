@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.AsyncLayout {
  :global {
    .ant-layout-content {
      overflow: hidden;

      & > .ant-page-header {
        #border.base(bottom);
        padding: @padding-xs @padding-md;

        .ant-page-header-heading-title,
        .ant-breadcrumb-link {
          font-size: @font-size-lg;
        }
      }
    }

    .ant-collapse-item:last-of-type {
      border-bottom: none;
    }
  }
}

.Content {
  background-color: @white;
  border-radius: @border-radius-md;
  #border.with-color(@gray-5);

  @media @phone {
    border: none;
    border-radius: 0;
  }
}
