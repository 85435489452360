@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  padding-bottom: 0;
  height: fit-content;
  min-height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;

  :global {
    .ant-layout-sider-trigger {
      display: none;
    }
  }

  @media @phone {
    height: 100%;
    position: fixed;
    z-index: 999;

    :global {
      .ant-layout-sider-zero-width-trigger {
        align-items: center;
        border-radius: 0;
        display: flex;
        height: @mobile-header-height;
        justify-content: center;
        top: 0;
      }
    }
  }
}

.Logo {
  background: @turquoise-5;
  overflow: hidden;
  padding: @padding-sm;
  position: relative;

  @media @phone {
    display: none;
  }

  :global {
    .ctra-branding-icon {
      display: block;
      fill: @teal-9;
      height: 28px;
      left: @spacing-sm;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .with-text {

    }

    .no-text {

    }
  }
}

.Sider {
  height: 100%;

  :global {
    main.ant-layout-content {
      display: flex;
      flex-direction: column;
      height: fit-content;
      min-height: calc(100vh - 64px);
    }

    .ant-menu-inline-collapsed {
      width: @menu-collapsed-width !important;
    }
  }
}
