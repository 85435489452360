@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Bar {
  font-size: @font-size-lg;
  font-weight: 500;
  min-width: max-content;
  padding: 0 @padding-xs;
}

.Light {
  background-color: @turquoise-4;

  :global {
    .ant-typography {
      color: @teal-9;
    }
  }
}

.Dark {
  background-color: @teal-9;

  :global {
    .ant-typography {
      color: @white;
    }
  }
}

.Neon {
  background-color: @teal-6;

  :global {
    .ant-typography {
      color: @white;
    }
  }
}

.LightGray {
  background-color: @gray-2;

  :global {
    .ant-typography {
      color: @gray-6;
    }
  }
}

.DarkGray {
  .LightGray;
  background-color: @gray-3;
}