@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  margin-right: @margin-md;

  :global {
    .ant-select {
      width: 100%;
    }
  }
}

.Divider {
  margin: @margin-xs;
}

.InputCol {
  align-items: center;
  display: flex;
  margin-bottom: @margin-xs;
  padding: 0 @padding-sm;
}

.Link {
  cursor: pointer;
  padding: 0 @padding-xs;
}
