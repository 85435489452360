@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Tag {
  position: relative;
  top: 8px;
  background-color: @turquoise-1;
  border-color: @turquoise-7;
}

.Col {
  min-height: 24px;
}
