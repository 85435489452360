@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Tag {
  border: none;
  :global {
    .ant-typography {
      color: @purple-6;
    }
  }
}
