@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  #border.with-color(gray-3);
  background-color: @white;
  border-radius: @border-radius-xl;
  padding: @padding-md;

  :global {
    svg {
      color: @gray-7;
    }
  }
}

.Icon {
  :global {
    .anticon {
      svg {
        #dimensions.square-dimensions(36px);

        circle {
          fill: @turquoise-2;
        }
      }
    }
  }
}

.Description {
  font-size: @font-size-lg;
}

.Extra {
  margin-left: auto;
}

.FromNow {
  color: @gray-5 !important;
}

.EventOriginContainer {
  background-color: @gray-2;
  padding: @spacing-xss @spacing-xs;
  border-radius: @border-radius-md;
  margin-top: 12px;
}
