@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.TabNavigation {
  background: none;

  :global {
    .ant-menu-item {
      padding-left: @padding-md !important;
      padding-right: @padding-md !important;
    }

    .ant-menu-item-selected {
      .ant-badge {
        color: @aqua-5;
      }
    }
  }
}

.ToFollowUpBadge {
  sup {
    background-color: @orange-6;
  }
}
