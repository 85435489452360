@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.UserMenu {
  button {
    color: @white;
    padding-left: @padding-xs;
    padding-right: @padding-xs;

    &:hover,
    &:focus {
      color: @connecterra-teal;
    }

    &:active {
      color: @teal-7;
    }
  }
}

.Icon {
  font-size: @header-icon-size;
}
