@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  overflow: hidden;
}

.Sider {
  background-color: @turquoise-3;
  padding: @padding-xxl;
  position: relative;
}

.HugeText {
  font-size: @font-size-xl * 2;
  font-weight: 600;
  line-height: 120%;
  color: @teal-10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Content {
  padding: @padding-xxl;
}

.Alert {
  margin-bottom: @margin-xl;
}
