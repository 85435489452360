@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ContentWrapper {
  background: @white;
  border-radius: @border-radius-lg * 2;

  :global {
    .ctra-contentWrapper {
      border: 0 none !important;
      filter: none;
    }

    .ant-skeleton {
      padding: @padding-md;
    }
  }
}

.AccentWhite {
  &.Border {
    #border.with-color(@gray-4, all);
  }

  .Title {
    #border.with-color(@gray-4, bottom);
    font-weight: 600;
  }

  .Footer {
    #border.with-color(@gray-4, top);
  }
}

.AccentTeal {
  background-color: @teal-1;

  &.Border {
    #border.with-color(@teal-3, all);
  }

  .Title {
    #border.with-color(@teal-3, bottom);
  }

  .Footer {
    #border.with-color(@teal-3, top);
  }
}

.Interactive {
  cursor: pointer;

  &:hover {
    border-color: @aqua-6;
  }
}

.Padded {
  padding: @padding-md;

  :global {
    .ant-skeleton {
      padding: 0;
    }
  }
}