@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.PageWrapper {
  padding: @padding-lg 0;
}

.RangePicker {
  margin-bottom: @margin-lg;
}

.Title {
  margin-top: @margin-xxl;

  @media @phone {
    margin-top: @margin-md;
  }
}

.Table {
  padding-top: @padding-md;

  :global {
    .ctra-impactTracking-widgetWrapper-content {
      padding: @padding-sm @padding-md;
    }
  }

  @media @phone {
    padding-top: 0;
  }
}

.TableEntry {
  padding: @padding-sm 0;
}
