@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ListItem {
  #border.base(bottom);
}


.Wrapper {
  background-color: @gray-2;
  cursor: pointer;
}

.Sub {
  font-size: @insight-list-sub-font-size;
}

.Unread {
  position: absolute;
  transform: translate(-10px, -10px) scale(0.75);

  :global {
    svg {
      fill: @red-6;
    }
  }
}

.Barn {
  #icon.secondaryColor();
}
