@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.LoginForm {
  :global {
    .ant-form-item {
      margin-bottom: @margin-md;
    }

    .ant-input-affix-wrapper-status-error {
      border-color: @red-7 !important;

      svg {
        fill: @red-7;
      }
    }

    .ant-form-item-explain-error {
      color: @red-7;
      padding: 10px 0;
    }
  }
}

.Extra {
  margin-top: @margin-md;
}

.Flat {
  :global {
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
