@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Panel {
  &:last-child {
    border-bottom: 0;
  }

  :global {
    .ant-collapse-header {
      .anticon-right {
        color: @gray-6;
        position: absolute;
        right: @padding-md;
        top: 50%;
        transform: translateY(-50%);
      }

      .ant-typography-secondary {
        margin-right: @margin-md;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .ant-list-item {
      cursor: pointer;
      padding: @padding-md @padding-sm;
      border-bottom: 1px solid @gray-4;
    }
  }
}
