@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  display: flex;
  position: relative;
  height: @height-base;
  @media @phone {
    height: auto;
  }
  justify-items: flex-end;
}

.EventWidget {
  :global {
    .ant-collapse-icon-position-left {
      & > .ant-collapse-item {
        & > .ant-collapse-header {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.Analysis {
  color: @aqua-5;
  border-color: @aqua-5;
  :global {
    .ant-typography {
      color: @aqua-5;
    }
  }
}
