@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &:before {
    background: url("../../../../../components/assets/png/grass.jpg");
    background-size: cover;
    content: " ";
    height: 100%;
    position: fixed;
    width: 100%;
  }
}

.Header {
  flex: 0;
  padding: @padding-md @padding-sm;

  @media @phone {
    padding: @padding-sm;
  }
}

.Content {
  flex: 1;
  position: relative;
  z-index: 1;

  @media @phone {
    padding-top: @padding-lg;
  }
}

.ChildrenWrapper {
  margin-bottom: auto;
  margin-top: auto;
  transform: translateY(-80px);

  :global {
    @media @phone {
      flex: 1;
      transform: translateY(0px);
    }
  }
}

.Branding {
  :global {
    svg {
      fill: @gray-5;
      height: 24px;
    }
  }
}

.Footer {
  flex: 1;
  flex-direction: column;
  padding: @padding-lg 0;

  :global {
    svg {
      fill: @gray-8;
      max-height: 24px;
    }
  }
}
