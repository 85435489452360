@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.AuthWidgetWrapper {
  @media @phone {
    padding: 0 @padding-sm;
    width: 100%;

    :global {
      .ant-space-item:last-child {
        width: 100%;
      }
    }
  }
}

.IntroTitle {
  margin-bottom: 40px !important;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

  :global {
    .ant-typography {
      color: @white;
    }
  }

  @media @phone {
    margin-bottom: @margin-md !important;
    text-align: center;
  }
}

.Smile {
  @media @phone {
    :global {
      svg {
        #dimensions.square-dimensions(32px);
      }
    }
  }
}

.Title {
  color: @gray-7 !important;
  font-size: @font-size-lg;
  font-weight: 500;
  margin-bottom: @margin-md !important;
}

.NoWhitespace {
  margin-bottom: 0 !important;
}

.Description {
  margin-bottom: @margin-md !important;
}

.Content {
  padding: @padding-xl;
  min-width: 540px;

  @media @phone {
    min-width: 0;
  }
}

.ContentWrapper {
  @media @phone {
    :global {
      .ant-space-vertical {
        width: 100%;

        & > .ant-space-item {
          width: 100%;
        }
      }
    }
  }
}

.Footer {
  padding: @padding-lg @padding-xl;
}