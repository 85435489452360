@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Text {
  :global {
    .ant-col {
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
    }
  }
}

.Bars {
  #border.with-color(@gray-3, right);
  border-width: 2px;
  padding: 0 @padding-lg 0 0 !important;
}

.Wrapper {
  container-type: inline-size;
  container-name: kpiChange-wrapper;
  overflow: hidden;
}

.MetricWrapper {
  container-type: inline-size;
  container-name: kpiChange-metric-wrapper;
  width: 200px;
}

.KpiCell {
  :global {
    strong {
      font-weight: 500;
    }
  }
}