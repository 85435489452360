@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.SectionHeader {
  padding-left: 0;
}

.Title {
  color: @aqua-5;
  padding-left: @padding-sm !important;
}
