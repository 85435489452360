@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Event {
  position: relative;

  :global {
    .ant-timeline-item-head {
      left: 15px;
      top: 15px;

      svg {
        #dimensions.square-dimensions(30px);

        path {
          fill: @gray-7;
        }

        circle {
          fill: @turquoise-2;
        }
      }
    }

    .ant-timeline-item-tail {
      left: 13px;
    }

    .ant-timeline-item-content {
      top: 0;
      margin-left: calc(30px + @padding-sm);
    }
  }
}

.Highlight {
  background-color: fade(@aqua-9, 20%);
  border-radius: 2px;
  padding: @padding-xs;
}