@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  overflow: hidden;

  :global {
    .ctra-impactTracking-valueChangeMetric-direction {
      line-height: 100% !important;
    }
  }
}

.Title {
  margin-bottom: 0 !important;
  font-weight: 500 !important;
}

.Icon {
  font-size: 32px;
  margin: 0 @margin-lg;
}
