@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.RangePicker {
  :global {
    @media (max-width: 576px) {
      .ant-picker-panels {
        flex-direction: column;
      }
    }
  }
}

.Caret {
  display: block;

  :global {
    svg {
      #dimensions.square-dimensions(10px);
    }
  }
}
