@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Icon {
  :global {
    svg {
      #dimensions.square-dimensions(40px);
      fill: @gray-8;

      @media @phone {
        #dimensions.square-dimensions(24px);
      }
    }
  }
}
