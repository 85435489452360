@import "~antd/dist/antd.less";
@import "~react-tiny-fab-ak/dist/styles.css";

// IBM Plex Sans

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Regular.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Thin.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-ThinItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-ExtraLightItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Light.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Italic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-SemiBoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Medium.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-Bold.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-LightItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../assets/fonts/subset-IBMPlexSans-MediumItalic.woff2") format("woff2"),
    url("../../assets/fonts/subset-IBMPlexSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// BODY

body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  overflow-x: hidden;
}

// FORM

.ant-form-item-label > label {
  font-weight: @form-item-label-font-weight;
}

// ALERT

.ant-alert-with-description .ant-alert-message {
  font-size: @font-size-xl - 2px !important;
}

.ant-alert-with-description .ant-alert-description {
}