@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ChartCard {
  padding: @padding-sm @padding-md;
  padding-bottom: 0;
  border-radius: 20px;
  &:hover {
    border-color: @aqua-6;
  }
}

.Icons {
  :global {
    svg {
      #dimensions.square-dimensions(18px);
    }
    .ant-badge-count {
      background-color: @gray-7;
    }
  }
}

.Title {
  font-size: @font-size-xl;
  color: @gray-9;
}

.Metric {
  margin-top: @margin-md;
  margin-bottom: @margin-lg;
  font-size: @font-size-md;
  :global {
    svg {
      fill: @gray-6;
      #dimensions.square-dimensions(14px);
    }
    .ant-space-item {
      line-height: 0;
    }
  }
}

.Dropping {
  border-right: 2px dashed @aqua-4;
  padding-right: @padding-md;
  box-sizing: content-box;
}

.Div {
  cursor: grab;
  transform: translate(0, 0);
}
