@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.CopyLinkWrapper {
  width: 100%;

  :global {
    .ant-space-item:first-child {
      flex: 1;
    }
  }
}