.g2-lineChart-tooltip {
  top: 0 !important;
  transform: translateY(-100%) !important;
}

/* Empty list item is considered a divider */
.g2-tooltip-list-item:has(.g2-tooltip-name:empty) {
  border-top: 1px dashed #ccc;
  height: 0;
  margin: 0;
  padding: 0;
}

/* Hide the bottom dashed border for tooltips which don't have a footer */
.g2-barChart-tooltip .g2-tooltip-list-item:has(.g2-tooltip-name:empty):last-of-type,
.g2-columnChart-tooltip .g2-tooltip-list-item:has(.g2-tooltip-name:empty):last-of-type {
  display: none !important;
}

.g2-tooltip-list-item {
  display: flex;
}

.g2-tooltip-list-item .g2-tooltip-name {
  flex: 1 0 auto;
}
