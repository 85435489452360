@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.PanelHeader {
  height: @panel-header-height;
  padding: @padding-md;
}

.Col {
  margin: @margin-xss 0;
}

.Hidden {
  visibility: hidden;
}

.Tag {
  margin-left: @margin-md;
}

.Description {
  font-size: @font-size-base + 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Date {
  color: @gray-6;
  font-size: @font-size-sm;
}

.Icon {
  :global {
    svg {
      #dimensions.square-dimensions(30px);
      fill: @aqua-base;
    }
  }
}
