@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Collapse {
  background: none;

  :global {
    .ant-collapse-item {
      min-height: 50px;
      position: relative;
      padding-bottom: @padding-lg;

      .ant-collapse-header {
        max-width: 70%;
        overflow: hidden;
        padding: 0 @padding-lg 0 0;
        position: unset;
        text-overflow: ellipsis;
        white-space: nowrap;

        /**
        * Expand Icon
        **/
        .ant-collapse-arrow {
          margin: 0;
          padding: 0;
          top: 12px;
          right: 0;
        }

        .ant-collapse-extra {
          bottom: 0;
          color: @gray-7;
          position: absolute;
          width: 100%;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          overflow: auto;
          white-space: normal;
        }
      }

      /**
        * Description
        **/
      .ant-collapse-content {
        color: @gray-7;

        .ant-collapse-content-box {
          padding: @padding-xs 0;
        }
      }
    }
  }
}
