@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.InsightItem {
  width: 100%;
  margin: 0 !important;
}

.Icon {
  :global {
    .anticon {
      display: block;
    }

    svg {
      #dimensions.square-dimensions(28px);
    }
  }
}

.Hidden {
  visibility: hidden;
}

.Title {
  :global {
    .ant-typography {
      color: @gray-8;
      font-weight: 500;
      max-width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

.Caret {
  color: @gray-8;
}
