@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Selector {
  padding: @padding-md;
  :global {
    .ant-space {
      gap: 0 !important;
    }
    .ant-select-selector {
      border-radius: 0 !important;
      border-right: 0 !important;
    }
  }
}

.InfoButton {
  font-size: @font-size-sm;
  padding: 0 @padding-sm;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  color: @blue-6;
  border-color: @blue-4;
  background-color: @blue-1;
}
.Info {
  .InfoButton;
  &:hover,
  &:focus,
  &:active {
    .InfoButton;
  }
}
