@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ListHeader {
  #border.base(bottom);
  background-color: @gray-1;

  :global {
    .ant-col {
      padding: @padding-md;
    }
  }
}
