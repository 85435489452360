@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Badge {
  display: block;
}

.Icon {
  color: @gray-7;

  :global {
    svg {
      #dimensions.square-dimensions(22px);
    }
  }
}

.Count {
  font-weight: 500;
  text-align: center;
  width: 60px;

  :global {
    .ant-typography {
      color: @gray-10;
      font-size: @heading-2-size;
      white-space: nowrap;

      @media @phone {
        font-size: 38px;
      }
    }
  }
}

.Text {
  color: @gray-7;
  font-size: @font-size-lg;
  font-weight: 400;

  @media @phone {
    font-size: @font-size-lg;
  }
}
