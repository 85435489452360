@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  max-height: @graph-custom-height;
  overflow: auto;
  padding-bottom: @padding-md;
  padding-left: @padding-md;
  width: 100%;
}

.Zoom {
  max-height: 75vh;
  overflow: auto;
  padding-bottom: @padding-md;
  padding-left: @padding-md;
  width: 100%;

  @media @phone {
    max-height: 68vh;
  }
}

.Table {
  :global {
    .ant-table-container {
      table {
        thead > tr > th:first-child {
          width: 80px;
        }
      }
    }
  }
}
