@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.PageContent {
  padding: @padding-xxl;
  min-height: 100%;

  @media @phone {
    padding: @padding-md;
  }
}

.Wrapper {
  margin-top: @margin-md;
}