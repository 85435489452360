@container kpiChange-wrapper (max-width: 500px) {
  .ctra-impactTracking-kpiChange-metricWrapper {
    width: 100px !important;
  }
}

@container kpiChange-metric-wrapper (max-width: 100px) {
  .ctra-impactTracking-valueChangeMetric {
    flex-direction: column;
    margin: 0 16px;
  }

  .ctra-impactTracking-valueChangeMetric-noData {
    align-items: flex-start;
  }

  .ctra-impactTracking-valueChangeMetric .ant-col {
    font-size: 14px;
    margin: 0;
  }

  .ctra-impactTracking-valueChangeMetric .anticon svg {
    height: 24px;
    width: 24px;
  }

  .ctra-impactTracking-valueChangeMetric h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .ctra-impactTracking-valueChangeMetric h5 + span {
    display: none;
  }

  .ctra-impactTracking-valueChangeMetric h5 small {
    font-size: 12px;
    margin-left: 4px;
  }

  .ctra-impactTracking-valueChangeMetric-direction {
    display: none;
  }

  .ctra-impactTracking-valueChangeMetric-noData-message {
    display: block;
    line-height: 120%;
  }
}