@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  margin-bottom: @margin-lg;

  :global {
    .ctra-widgetWrapper-header {
      padding-bottom: 0;
    }
  }
}