@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@button-wh: 52px;

.FarmSelect {
  :global {
    .ant-select {
      width: 100%;
    }

    .ant-select-focused .ant-select-selector {
      outline: 0;
      box-shadow: none !important;
      border-color: @gray-5 !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 0;
    }

    .ant-select-selector,
    .ant-select-selection-search,
    .ant-select-selection-placeholder,
    input {
      height: @button-wh !important;
    }

    .ant-select-selection-item {
      .ant-row {
        height: 100%;
      }

      .ant-col:first-child,
      .ant-typography-secondary {
        display: none;
      }

      .ant-col:last-child {
        margin-left: @margin-xs;
      }
    }
  }
}

.Dropdown {
  padding: 0;

  :global {
    .ant-select-item-option {
      margin: 0;
      padding: 0;
    }
  }
}
