@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.NoHeight {
  line-height: 0;
}

.Icon {
  #dimensions.square-dimensions(22px);

  svg {
    #dimensions.square-dimensions(22px);

    circle {
      fill: @turquoise-2;
    }
  }
}

.Small {
  font-size: @font-size-md;
  font-weight: 500;
}

.Collapse {
  background-color: transparent;
  border: 1px solid @collapse-blue-border;
  border-radius: @border-radius-sm;
  margin-bottom: @spacing-sm;

  .Panel {
    background-color: @collapse-blue-background;
  }

  :global {
    .ant-collapse-content-box {
      background-color: @collapse-blue-background;
    }
  }
}
