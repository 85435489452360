@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@import "~antd/lib/button/style/mixin";

.Default {
  .button-color(@teal-8, @white, @btn-default-border);

  &:hover,
  &:focus {
    .button-color(@teal-9, @white, @teal-9);
  }

  &:active {
    .button-color(@teal-10, @white, @teal-10);
  }
}

.Primary {
  .button-color(@teal-9, @turquoise-6, @turquoise-6);
  font-weight: 700;
  text-shadow: none;

  &:hover,
  &:focus {
    .button-color(@teal-9, @turquoise-5, @turquoise-5);
  }

  &:active {
    .button-color(@teal-9, @turquoise-7, @turquoise-7);
  }
}

.Secondary {
  .button-color(@teal-8, @white, @teal-8);
  font-weight: 700;

  &:hover,
  &:focus {
    .button-color(@teal-9, @white, @teal-9);
  }

  &:active {
    .button-color(@teal-10, @white, @teal-10);
  }
}

.Tertiary {
  .Default;
  .button-color(@black, @white, @black);
  border-radius: @border-radius-md;
}

.Ghost {
  .button-color(@btn-default-ghost-color, @btn-default-ghost-bg, @btn-default-border);

  &:hover,
  &:focus {
    .button-color(@turquoise-6, @btn-default-ghost-bg, @turquoise-6);
  }

  &:active {
    .button-color(@turquoise-7, @btn-default-ghost-bg, @turquoise-7);
  }
}

.Dashed {
  .Default;
}

.Danger {
  .btn-danger-default();
}
