@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Entry {
  #border.base(bottom);
  border-color: @gray-2;
  cursor: pointer;
  padding: @padding-sm;

  &:hover {
    background-color: @primary-1;
  }

  &:last-of-type {
    border: none;
  }

  @media @phone {
    min-width: 150%;
  }
}

.Wrapper {
  margin-top: 0;
  padding: @padding-md;
}

.Skeleton {
  margin-top: @margin-md;
}

.Header:extend(.Entry) {
  color: @gray-7;
}

.AddChartLink {
  display: flex;
  font-weight: 700;
  margin-left: auto;
  text-decoration: underline;
}

.Table {
  #border.with-color(@gray-2, all, @border-radius-lg);
  background-color: @white;
  margin: 0 !important;
}

.RelatedTitle {
  padding: @padding-md !important;
}

.Tabs {
  :global {
    .ant-tabs-tab {
      margin-left: @margin-md;
      padding: @padding-md 0;
    }
  }
}

.NoCombinedCorrelations {
  border: none;
  border-radius: 0;
  margin-top: -@margin-md;
}

.Metrics {
  @media @phone {
    overflow-x: scroll;
  }
  padding: 0 !important;
}

.Title {
  padding: 0 @padding-md;
  font-size: @font-size-md;
  font-weight: 500;
  :global {
    svg {
      #dimensions.square-dimensions(20px);
      fill: @blue-7;
    }
    .ant-typography {
      margin: 0 @margin-sm;
    }
    .ant-btn > span {
      text-decoration: underline;
    }
  }
}

.Alert {
  margin-top: @margin-md;
  padding: @padding-xs 0 !important;
}
