@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Empty {
  background-color: @white;
  padding: @padding-lg;

  :global {
    .anticon {
      display: block;
      color: @gray-6;

      svg {
        #dimensions.square-dimensions(22px);
      }
    }
  }
}

.Text {
  color: @gray-7;
  font-size: @font-size-lg;
  font-weight: 400;
}