@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@icon-size: 36px;
@spacingV: @padding-sm;
@spacingH: @padding-md;

.TimelineItem {
  border-radius: @border-radius-lg;
  position: relative;
  padding: @spacingV @spacingH;

  :global {
    .ctra-impactTracking-timelineItem-title {
      strong {
        font-weight: 500;
      }
    }

    .ant-timeline-item-head {
      background-color: transparent;
      left: calc(@icon-size / 2 + @spacingH);
      top: calc(@icon-size / 2 + @spacingV);

      svg {
        #dimensions.square-dimensions(@icon-size);

        path {
          fill: @gray-7;
        }

        circle {
          fill: @turquoise-2;
        }
      }
    }

    .ant-timeline-item-tail {
      left: calc(@icon-size / 2 + @spacingH);
      top: calc(@icon-size + @margin-sm + @spacingV);
      height: calc(100% - (@icon-size + @margin-sm + @spacingV * 2));
    }

    .ant-timeline-item-content {
      margin-left: calc(@icon-size + @margin-md);
      top: 0;
    }
  }
}

.Highlighted {
  background-color: @turquoise-2;

  :global {
    .ctra-impactTracking-timelineItem-title {
      .ant-typography {
        color: @primary-color;
      }
    }
  }
}

.Date {
  :global {
    .ant-typography {
      color: @gray-6;
    }
  }
}

.Clickable {
  cursor: pointer;
}