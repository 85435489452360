@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  background-color: @white;
  height: calc(100% - @mobile-header-height - @mobile-footer-height);
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  padding-top: @padding-xs;
  position: fixed;
  top: @mobile-header-height;
  width: 100%;
  z-index: @zindex-modal;
}
