@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Icon {
  :global {
    svg {
      fill: @primary-color;
    }
  }
}

.Item {
  width: 100%;
  padding: @padding-xs 0;
  font-size: @font-size-md;
  cursor: pointer;
}

.Tag {
  background-color: @turquoise-1;
  border-color: @turquoise-7;
}
