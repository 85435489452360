@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  display: flex;
  flex-direction: column;
}

.Headless {
  :global {
    .ctra-impactTracking-widgetWrapper-content {
      border-top-left-radius: @border-radius-xl;
      border-top-right-radius: @border-radius-xl;
    }
  }
}

.Minimalistic {
  :global {
    .ctra-impactTracking-widgetWrapper-content, .ctra-impactTracking-widgetWrapper-header {
      border: none;
      padding: 0;
    }

    .ctra-impactTracking-widgetWrapper-header {
      padding-bottom: @padding-sm;
    }
  }
}

.Header {
  #border.with-color(@gray-3);
  border-bottom: none;
  border-top-left-radius: @border-radius-xl;
  border-top-right-radius: @border-radius-xl;
  flex: 0 0 auto;
  font-size: @font-size-lg;
  padding: @padding-sm @padding-md;

  :global {
    .ant-typography {
      color: @gray-7;
    }
  }
}

.Content {
  #border.with-color(@gray-3);
  background-color: @white;
  border-bottom-left-radius: @border-radius-xl;
  border-bottom-right-radius: @border-radius-xl;
  flex: 1 1 auto;
  overflow: hidden;
  padding: @padding-lg @padding-md;
}

.OverflowContainer {
  height: 100%;
  overflow: hidden;
}

.Scrollable {
  overflow-y: auto;
}