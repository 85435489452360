@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  #icon.empty;
  height: @farm-timeline-empty-height;
}

.HideIcon {
  :global {
    .ant-empty-image {
      display: none;
    }
  }
}
