@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Container {
  background-color: @white;
  max-width: 150px;
  #border.base(all);
  padding: 2px @padding-sm;
  border-radius: @border-radius-xl + 2px;
  :global {
    svg {
      #dimensions.square-dimensions(12px);
      fill: @gray-8;
      cursor: pointer;
    }
  }
}

.Disabled {
  :global {
    svg {
      fill: @gray-5;
    }
  }
}

.Numbers {
  gap: @padding-md;
  margin: 0 @margin-md;
}
