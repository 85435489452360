@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@nav-icon-mobile-height: 16px;

.Hidden {
  visibility: hidden;
}

.Icon {
  :global {
    svg {
      #dimensions.square-dimensions(@nav-icon-mobile-height);
      fill: @gray-6;
    }
  }
}
