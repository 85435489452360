@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  #icon.empty;
  height: @insight-list-empty-height;
}

.Empty {
  //#icon.empty;
  padding: @padding-md;
  margin: 0;
  width: 100%;
}
