@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Graph {
  width: 100%;

  :global {
    .g2-tooltip {
      max-height: 100%;
      overflow-y: auto;
    }
  }
}

.Zoom {
  min-height: 75vh;

  @media @phone {
    min-height: 68vh;
  }
}

.Events {
  width: 100%;
}

.BetaTag {
  background-color: @gray-8;
  border-color: @gray-10;
  color: @white;
  font-weight: normal;
  margin-left: @margin-xs;
  position: relative;
  top: -2px;
}

.Error {
  margin: @margin-md @margin-lg;
}

.Empty {
  margin: @margin-md;

  :global {
    svg {
      fill: @gray-6;
    }
  }
}

.HideIcon {
  :global {
    .ant-empty-image {
      display: none;
    }
  }
}

.Invisible {
  :global {
    svg {
      #dimensions.square-dimensions(38px);
      box-sizing: content-box;
      padding: @padding-lg;
      fill: @gray-6;
      background-color: @gray-2;
      border-radius: 50%;
      border: 2px solid @gray-5;
    }
  }
}

.SingleFarmDescription {
  width: 70%;
}
