@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  align-items: center;
  display: flex;
}

.Popover {
  max-width: 350px;
  width: fit-content;
  :global {
    .ant-popover-content {
      .ant-popover-arrow {
        .ant-popover-arrow-content {
          background-color: @connecterra-teal;
        }
      }
      .ant-popover-inner {
        background: @teal-gradient;
        padding-top: @padding-xs;

        .ant-popover-title {
          border: none !important;
          font-weight: bold;
          justify-content: space-between;
          width: 100%;
        }

        .ant-popover-inner-content {
          padding-top: 0;
        }
      }
    }
  }
}

.CloseIcon {
  cursor: pointer;
}

.Dot {
  :global {
    svg {
      #dimensions.square-dimensions(10px);
      fill: @white;
    }
  }
}

.Tag {
  align-items: center;
  background: @teal-gradient;
  border: none;
  color: @white;
  cursor: pointer;
  display: flex;
  margin-left: @margin-xss;
  :global {
    span {
      margin-right: @margin-xss;
    }
  }
}
