@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@widget-height: 32px;
@overflow: 8px;
@pointer-width: 4px;

.FarmProgress {
  position: relative;
  overflow: hidden;

  :global {
    .ctra-insight-generic-farmProgress-pointerLabel-wrapper {
      position: absolute;
      width: 100%;
    }

    .ctra-insight-generic-farmProgress-pointerLabel {
      white-space: nowrap;
      position: absolute;
    }

    .ctra-insight-generic-farmProgress-pointer {
      height: @widget-height + @overflow;
      width: @pointer-width;
      background: @teal-8;
      position: absolute;
      top: @widget-height + (@overflow / 2);
    }

    .ctra-insight-generic-farmProgress-gradient {
      background: linear-gradient(90deg, #FFCCC7 0%, #E3E3E3 51%, #BAEDBD 100%);
      height: @widget-height;
      margin: (@widget-height + @overflow) 0 8px 0;
      width: 100%;
    }
  }
}
