@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Select {
  :global {
    .ant-select-arrow {
      .anticon {
        line-height: 0;

        svg {
          #dimensions.square-dimensions(10px);
        }
      }
    }
  }
}

.Space {
  position: relative;
  :global {
    .ant-select-selection {
      &-placeholder {
        margin-left: @margin-lg !important;
      }
    }

    .ant-select-selection-overflow {
      &-item:first-child {
        margin-left: @margin-lg !important;
      }
    }
    svg {
      #dimensions.square-dimensions(16px);
      fill: @gray-7;
    }
  }
}

.Icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
