@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Label {
  :global {
    .ant-typography {
      color: @gray-7;
    }
  }
}

.Icon {
  :global {
    svg {
      #dimensions.square-dimensions(32px);
    }
  }
}
