@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.TimelineWrapper {
  height: 75vh;

  @media @phone {
    height: 45vh;
  }

  :global {
    .ctra-impactTracking-widgetWrapper-content {
      position: relative;
    }
  }
}

.ReduceTextSize {
  font-size: @font-size-md;
}

.GroupLabel {
  background: @gray-3;
  border-radius: @border-radius-sm;
  color: @gray-6;
  font-size: @font-size-md;
  padding: @padding-xs @padding-sm;
  margin: @margin-sm 0 !important;
  width: fit-content;
}

.Loading {
  background-color: rgba(255,255,255,0.5);
  padding: @padding-sm;
  position: absolute;
  right: 0;
  top: 0;
}