@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  padding: @padding-sm 0;

  :global {
    .ant-typography {
      color: @gray-8;
      margin-bottom: 0;
    }

    .title {
      color: @gray-7;
      font-size: @font-size-lg;
      font-weight: 500;

      svg {
        fill: @gray-7;
        #dimensions.square-dimensions(18px);
      }
    }
  }
}

.Hidden {
  visibility: hidden;
}
