@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.ViewFilter {
  margin-right: @margin-xs;
}

.Popover {
  max-width: @popover-width-md;
}

.Close {
  margin-left: @margin-sm;
}
