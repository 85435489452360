/**
 * Override some of the popover styles as it cannot
 * be targeted via themes.
 */
.ant-popover {
  .ant-popover-title {
    color: @white;
    #border.with-color(@gray-8, bottom);
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    a {
      color: @teal-4;
    }
  }
}
