@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ChartContainer {
  :global {
    .ant-spin-container {
      display: flex;
      flex-flow: wrap;
      justify-content: flex-end;

      & > .ant-col {
        margin: 0 12px 0 0;
        align-self: stretch;

        .ant-page-header {
          padding: 0;
          margin: 0;
        }
      }

      & > .ant-page-header {
        padding: 0;
        margin: 0;
      }

      & > .ant-row {
        flex: 1 0 100%;
        margin-top: @margin-md;
      }
    }
  }
}