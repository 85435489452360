@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.EventOriginIcon {
  align-items: center;
  border: @border-radius-xs solid @gray-7;
  border-radius: 50%;
  color: @gray-7;
  display: flex;
  font-size: @font-size-xs;
  font-weight: 700;
  height: @spacing-md;
  justify-content: center;
  width: @spacing-md;
}
