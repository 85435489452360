@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Empty {
  background-color: lighten(@aqua-2, 30);
  border-radius: @border-radius-lg * 2;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.Header {
  min-height: @panel-header-height;
  max-height: @panel-header-height;
  flex: 1;

  :global {
    .ant-typography {
      color: @aqua-6;
      font-size: @heading-6-size;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
}

.Content {
  align-items: center;
  background-color: lighten(@aqua-3, 35);
  border-radius: @border-radius-lg * 2;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 40px;
  text-align: center;

  :global {
    .ant-typography {
      color: @aqua-5;
      font-size: @font-size-lg;
    }
  }
}

.Icon {
  #dimensions.square-dimensions(120px);
  position: relative;

  :global {
    svg {
      #dimensions.square-dimensions(120px);
      fill: @aqua-6;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.IconBackground {
  background-color: lighten(@aqua-3, 30);
  border-radius: 10rem;

  :global {
    svg {
      #dimensions.square-dimensions(70px);
    }
  }
}
