@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Icon {
  cursor: default !important;
}

.InputGroup {
  display: flex !important;
  flex-wrap: nowrap;
}

.Select {
  min-width: 290px;

  @media @phone {
    min-width: 200px;
    max-width: 200px;
  }

  :global {
    .ant-select-selector {
      border-color: @select-border-color !important;
      box-shadow: none !important;
    }
  }
}
