@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@button-wh: 52px;

.center-wh {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.FarmOption {
  padding: 0;
  height: @button-wh;

  :global {
    .ant-typography {
      display: block;
    }
  }
}

.Icon {
  position: relative;
  width: @button-wh;
  height: 100%;

  :global {
    .anticon {
      .center-wh();

      svg {
        fill: @gray-7;
      }
    }
  }
}