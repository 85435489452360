@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ApplicationWrapper {
  min-height: 100vh;
}

.SiderWrapper {
  padding-bottom: 0;
  height: fit-content;
  min-height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;

  :global {
    .ant-layout-sider-trigger {
      display: none;
    }
  }

  @media @phone {
    height: 100%;
    position: fixed;
    z-index: 999;

    :global {
      .ant-layout-sider-zero-width-trigger {
        align-items: center;
        border-radius: 0;
        display: flex;
        height: @mobile-header-height;
        justify-content: center;
        top: 0;
      }
    }
  }
}

.SiderContent {
  height: 100%;

  :global {
    main.ant-layout-content {
      display: flex;
      flex-direction: column;
      height: fit-content;
      min-height: calc(100vh - 64px);
    }

    .ant-menu-inline-collapsed {
      width: @menu-collapsed-width !important;
    }
  }
}

.Logo {
  background: @turquoise-5;
  overflow: hidden;
  padding: @padding-sm;
  position: relative;

  @media @phone {
    display: none;
  }

  :global {
    .ctra-branding-icon {
      display: block;
      fill: @teal-9;
      height: 28px;
      left: @spacing-sm;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .with-text {
    }

    .no-text {
    }
  }
}

.HeaderWrapper {
  align-items: center;
  display: flex;

  @media @phone {
    height: @mobile-header-height;
    overflow: hidden;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;

    :global {
      h4.ant-typography {
        color: @white;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }
}

.Sandbox {
  background-color: @gray-8;
}

.PageContent {
  position: relative;
  z-index: 1;

  @media @phone {
    margin-bottom: @mobile-footer-height;
  }

  :global {
    .ant-drawer-content-wrapper {
      width: 25% !important;
      @media @phone {
        width: 100% !important;
      }
    }

    .ant-drawer {
      z-index: @zindex-table-fixed;
    }
  }
}

.FooterWrapper {
  #border.with-color(@gray-4, top);
  bottom: 0;
  display: flex;
  /**
  * Min-height is preferred over height to cater for devices
  * with notches (e.g iPhone X)
  **/
  min-height: @mobile-footer-height;
  padding: 0;
  position: fixed;
  width: 100%;
  /**
  * The Footer buttons should show above the modal mask
  * which has the highest z-index used in the app
  **/
  z-index: @zindex-modal + 1;
}
