@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  margin-bottom: @margin-lg;
  :global {
    .ant-layout-content {
      padding: @padding-md @padding-md @padding-md 0;

      .ant-collapse {
        .ant-collapse-header {
          padding: 0;

          h4 {
            padding-left: @padding-md;
            margin-bottom: 0;
            line-height: 1.4;
            /**
              * Text gradient for the title. 
              * Should move this to a mixin if another usecase comes up
            **/
            background: @teal-gradient;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            /**
              * The width is set so that we can visualize the gradient
              * That way, the gradient is spread only across the text and not the entire page width.
            **/
            width: fit-content;

            svg {
              height: 20px;
              width: 20px;
              fill: @connecterra-teal;

              margin-right: @margin-xs;
            }
          }
        }

        .ant-collapse-content {
          .ant-typography {
            margin-bottom: 0;
          }

          .ant-collapse-content-box {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
