@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Card {
  :global {
    .ctra-anomaly-toggle {
      margin: 0 -@margin-lg;
    }
  }
}

.Empty {
  background-color: @gray-1;

  :global {
    svg {
      #dimensions.square-dimensions(22em);
    }
    span {
      text-decoration: underline !important;
      font-weight: bold;
    }
    h4.ant-typography {
      color: @gray-6 !important;
      font-size: @font-size-xxl;
    }
  }
}
