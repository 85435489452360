@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.PageWrapper {
  padding: @padding-xxl;

  @media @phone {
    padding: @padding-md;
  }

  :global {
    .ctra-widgetWrapper:not(:last-child) {
      margin-bottom: @margin-lg;
    }
  }
}

.Label {
  margin-bottom: @margin-xs !important;
}

.Free {
  margin: @margin-md;
}
