@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Progress {
  margin-right: @margin-xs;
  :global {
    .ant-progress-steps-item {
      width: 33px !important;
    }
    .ant-progress-steps-item-active {
      background: @aqua-4;
    }
  }
}
