@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.WidgetWrapper {
  margin-top: -@padding-sm;
}

.Wrapper {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.Options {
  #border.with-color(@gray-4, all, @border-radius-lg * 2);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid @gray-4;
  border-bottom: 0;
  background-color: @gray-1;
  padding: @padding-md;
  gap: @padding-sm;
}

.Selector {
  padding: @padding-md;
}

.Close {
  margin-right: @margin-md;
  :global {
    svg {
      fill: @gray-6;
      cursor: pointer;
    }
  }
}

.Chart {
  padding: @margin-md;
}

.RightMargin {
  margin: 0 @margin-lg;
  svg {
    margin-right: @margin-xs;
  }
}

.Saved {
  margin: 0 @margin-lg;
  :global {
    svg {
      fill: @teal-9;
      margin-right: @margin-xs;
    }
  }
}

.Dropdown {
  :global {
    .ant-select {
      border: 2px solid blue !important;
    }
  }
}

.Buttons {
  height: 0;
  position: relative;
  top: @padding-lg;
}
