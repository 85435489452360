@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.StatusDropdown {
  #border.with-color(@gray-4, all, @border-radius-xxl);
  background-color: @gray-3;
  color: @gray-8;
}

.ToFollowUp {
  color: @teal-7;
  background-color: @teal-1;
  #border.with-color(@teal-7);
  &:hover:extend(.ToFollowUp),
  &:focus:extend(.ToFollowUp) {
    opacity: 0.7;
  }
}

.Done {
  color: @green-8;
  background-color: @green-1;
  #border.with-color(@green-8);
  &:hover:extend(.Done),
  &:focus:extend(.Done) {
    opacity: 0.7;
  }
}