@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Icon {
  border-radius: 50%;
}

.Increase:extend(.Icon) {
  background-color: @teal-2;
}

.Decrease:extend(.Icon) {
  background: #e68ae54d;
}

.Stagnate:extend(.Icon) {
  background: @gray-2;
}

.Small {
  padding: @padding-xss;
}

.Large {
  padding: @padding-xs + 2px;
}
