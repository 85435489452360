@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  margin-bottom: @margin-lg;

  :global {
    .ctra-widgetWrapper-header {
      padding-bottom: 0;
    }
  }
}

.Tabs {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: 0;
      }
    }

    .ant-tabs-nav-wrap {
      padding: 0 @padding-md;
    }

    .ant-badge-count {
      right: -8px;
    }
  }
}