@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Base {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  box-shadow: none;
  transition: none;
}

.Settings:extend(.Base) {
  #border.base(top);
  padding: @padding-lg;
  padding-right: @padding-sm;
  width: 100%;

  &:focus,
  &:active,
  &:hover {
    .Base;
    #border.base(top);
    color: inherit;
  }

  img {
    height: 1em;
    width: 1em;
    margin-right: 10px;
  }

  :global {
    .anticon.anticon-right {
      margin-left: auto;

      svg {
        fill: @gray-6;
      }
    }
  }
}

.Dashboard:extend(.Base) {
  :global {
    .anticon,
    .anticon-global {
      font-size: @font-size-sm;
    }
  }
}

.Auth:extend(.Base) {

}

.Group {
  :global {
    .ant-menu-item-group-title {
      padding: @padding-sm;
    }
    .ant-menu-item {
      padding: 0 @padding-lg;
      margin: 0 !important;
      &:first-child {
        #border.base(top);
      }
      #border.base(bottom);
    }
    .ant-menu-item-selected {
      background-color: inherit !important;
    }
  }
}

.DashboardGroup {
  :global {
    .ant-menu-item {
      padding: 0 @padding-lg;
      margin: 0 !important;
      border: none;
    }

    .ant-dropdown-menu-item-icon {
      margin-right: @margin-xs;
    }
  }
}