@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Plus {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: linear-gradient(@white 0 0), linear-gradient(@white 0 0);
  background-position: center;
  background-size: 50% 2px, 2px 50%; /* thickness = 2px, length = 50% */
  background-repeat: no-repeat;
}

.FABWrapper {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  @media @phone {
    bottom: 55px;
  }

  :global {
    .rtf--mb__c {
      .rtf--mb {
        background-color: @aqua-6;

        &:hover {
          background-color: @aqua-5;
        }
      }
    }

    .rtf--ab__c {
      button {
        height: auto;
        width: auto;
        background-color: @gray-8;
        position: relative;
        border-radius: 2px;
        padding: @padding-xs @padding-sm;
        font-weight: 400;
        color: @white;
        font-size: @font-size-base;

        & + span {
          display: none;
        }

        .ant-typography {
          color: @white;
        }
      }
    }
  }
}
