@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ChartCard {
  padding-bottom: 0;
  cursor: pointer;
  &:hover {
    border-color: @aqua-6;
  }
}

.Title {
  font-size: @font-size-lg;
  color: @gray-9;
  margin-right: @margin-sm;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Metric {
  margin-top: @margin-md;
  margin-bottom: @margin-lg;
  font-size: @font-size-md;

  :global {
    svg {
      fill: @gray-6;
      #dimensions.square-dimensions(14px);
    }
    .ant-space-item:first-child {
      line-height: 0;
    }
  }
}

.Expand {
  margin-left: @margin-md;
}
