@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Margin {
  margin-bottom: @margin-md;
}

.Select {
  min-width: 470px;

  @media @phone {
    min-width: 200px;
    max-width: 200px;
  }

  :global {
    .ant-select-selector {
      border-color: @select-border-color !important;
      box-shadow: none !important;
    }
  }
}

.Modal {
  width: fit-content !important;
  :global {
    .ant-modal-confirm-content {
      #border.base(top);
      #border.base(bottom);
      padding: @padding-lg;
      margin-left: 0 !important;
    }
    .ant-modal-confirm-title {
      padding: @padding-md;
      padding-bottom: @padding-sm;
    }
    .ant-modal-confirm-btns {
      margin: @margin-md;
    }
  }
}

.Alert {
  margin-top: @margin-md;
  width: 500px !important;
  padding: @padding-md;
  :global {
    svg {
      fill: @orange-base;
    }
  }
}

.List {
  padding-left: @padding-xl;
  border: none !important;
}
