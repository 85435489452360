@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.SectionPanel {
  border: none !important;

  :global {
    .ant-collapse-header {
      background-color: transparent;
      border-bottom: 1px solid @gray-2;
    }

    .ant-collapse-content {
      background-color: transparent;
      border: none !important;
    }
  }
}
