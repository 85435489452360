@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Header {
  color: @gray-7 !important;
  font-weight: 400;
  margin-bottom: @margin-xl;
}

.Link {
  margin-bottom: @margin-md;
  :global {
    span {
      text-decoration: underline !important;
      @media @desktop {
        font-weight: bold;
      }
    }
  }
}

.Empty {
  .Link;
  margin-top: @margin-md;
  border-radius: @border-radius-sm;
  padding: @padding-lg;
  :global {
    svg {
      #dimensions.square-dimensions(22em);
    }
    .ant-typography {
      color: @gray-6 !important;
    }
  }
}

.Dropdown {
  margin: 0 @margin-md;
  .Link;
  color: @teal-8;
  text-decoration: underline;
}

.Modal {
  width: fit-content !important;
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-confirm-content {
      #border.base(top);
      #border.base(bottom);
      padding: @padding-lg;
      margin-left: 0 !important;
    }
    .ant-modal-confirm-title {
      padding: @padding-md;
      padding-bottom: @padding-sm;
    }
    .ant-modal-confirm-btns {
      margin: @margin-md;
    }
  }
}

@import "~antd/lib/button/style/mixin";

.Primary {
  .button-color(@teal-9, @turquoise-6, @turquoise-6);
}

.OkBtn {
  .Primary;
  &:hover,
  &:focus,
  &:active {
    .Primary;
  }
}

.Tabs {
  @media @phone {
    :global {
      div.ant-row {
        margin: 0 @margin-sm;
      }
    }
  }
}
