@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.TimePeriod {
  line-height: 0;
  overflow: hidden;

  :global {
    .ant-space-item {
      line-height: 0;
    }

    .anticon {
      svg {
        #dimensions.square-dimensions(18px);
        fill: @gray-7;
      }
    }

    .ant-typography {
      color: @gray-7;
    }
  }
}

.NoIcon {
  line-height: normal;
}
