@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Icon {
  font-size: @font-size-sm;
  margin-left: @margin-xss;
}

.Popover {
  max-width: @popover-width-lg;
}

:global {
  .ant-popover {
    z-index: @zindex-modal * 10;
  }
}
