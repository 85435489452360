@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Caret {
  display: block;

  :global {
    svg {
      #dimensions.square-dimensions(10px);
    }
  }
}