@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ChartHeader {
  padding-bottom: 0;

  :global(.ant-page-header) {
    #border.clear(bottom);
  }

  :global {
    /**
     * Set up page header to follow the chart designs
     */
    .ant-page-header-heading {
      flex-wrap: nowrap;

      .ant-page-header-heading-left {
        align-items: baseline;
        flex: 4;
        flex-direction: column;
        overflow: visible;

        .ant-page-header-heading-title {
          font-size: @font-size-lg;
          line-height: initial;
          overflow: visible;
          white-space: normal;
        }

        .ant-page-header-heading-sub-title {
          white-space: normal;
        }
      }
    }
  }
}
