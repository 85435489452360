@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ChartPicker {
  margin-top: @margin-sm;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  // add !important to override the accent selector in the widget CSS, which is strong
  border-bottom: 0 !important;

  :global {
    .ant-divider {
      margin: 0;
    }
    .ant-typography {
      margin: @margin-md @margin-lg;
      font-weight: 500;
    }
  }
}

.MetricPicker {
  padding: @padding-lg;
}

.Collapse {
  #border.base(all);
  border-color: @gray-5;
  font-size: @font-size-md;
  border-bottom-left-radius: @border-radius-xl;
  border-bottom-right-radius: @border-radius-xl;

  :global {
    .ant-collapse-item-active .ant-collapse-header {
      font-weight: 500;
    }
    .ant-collapse-item {
      margin: @margin-xs 0;
    }
    .ant-collapse-item:last-child {
      border: none;
    }

    .ant-collapse-item:last-child > .ant-collapse-content {
      border-bottom-left-radius: @border-radius-xl;
      border-bottom-right-radius: @border-radius-xl;
    }
    .ant-collapse-content-box {
      padding: 0 @padding-md;
    }
  }
}

.Header {
  padding-left: @margin-sm;
}

.MiniContainer {
  padding: @padding-lg 0;
}
