@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Button {
  &, &:focus {
    background: @teal-2;
    #border.with-color(@teal-2);
  }
}

.Enabled {
  &, &:focus {
    color: @teal-4;
    background: transparent;
    #border.with-color(@teal-4);
  }
}