@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Xs {
  font-size: @font-size-xs;;
}

.Sm {
  font-size: @font-size-sm;
}

.Md {
  font-size: @font-size-md;
}

.Lg {
  font-size: @font-size-lg;
}

.Xl {
  font-size: @font-size-xl;
}

.Xxl {
  font-size: @font-size-xxl;
}