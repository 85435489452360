@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ListWrapper {
  margin-top: @margin-md;
  overflow: hidden;
}

.Pagination {
  :global {
    .ant-list-pagination {
      margin-top: 0;
      background-color: @gray-1;
      display: flex;
      justify-content: center;
      padding: @padding-md 0;
    }
  }
}

.Hide {
  :global {
    .ant-list-pagination {
      display: none;
    }
  }
}

.All {
  margin: @margin-md 0;
}

.Header {
  border-bottom: none !important;
}
